import React from 'react';
import Typography from '../../fui/v2/Typography';
import Image from 'next/image';
import TextField from '@material-ui/core/TextField';
import Button from '../../fui/v2/Button';
import NavBar from '../layout/navbar/NavBar';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useRouter } from 'next/router';
import routes from '../../utils-ts/routes';
import { useQuery } from '@apollo/client';
import HomePageOffersQuery, {
  HomePageOffer,
  HomePageOffersQueryData,
} from '../../graphql-ts/queries/HomePageOffersQuery.graphql';
import numeral from 'numeral';
import dayjs from 'dayjs';
import {
  getRaiseOfferType,
  isEntitlementOrSpp,
} from '../../utils-ts/raise-helpers';
import Link from 'next/link';
// import { EVENTS } from '../../utils-ts/analytics';
// import { LogOnMount } from '../../utils-ts/offer-page-helper';

const images = [
  'communication-services.jpg',
  'consumer-discreationary-2.jpg',
  'consumer-discretionary.jpg',
  'consumer-staples-2.jpg',
  'consumer-staples.jpg',
  'energy-1.jpg',
  'energy-2.jpg',
  'energy-3.jpg',
  'financials.jpg',
  'health-care-1.jpg',
  'health-care-2.jpg',
  'industrial.jpg',
  'information-technology-1.jpg',
  'information-technology-2.jpg',
  'materials-1.jpg',
  'materials-2.jpg',
  'materials-3.jpg',
  'materials-4.jpg',
  'real-estate-2.jpg',
  'utilities-1.jpg',
  'utilities-2.jpg',
];

const getImage = (
  sector: string,
  offerId: string,
  matchingOffers: HomePageOffer[],
) => {
  const formatted = sector.toLowerCase().replace(/\s/g, '-');
  const matchedImages = images.filter((image) => image.includes(formatted));

  if (sector === 'Not Applicable' || !matchingOffers || !matchedImages)
    return '/images/assets/banner-placeholder.jpg';

  if (matchedImages.length === 1 || matchingOffers.length === 1) {
    return `/images/landing-page/offer-section/${matchedImages[0]}`;
  }
  const index = matchingOffers.findIndex((offer) => offer.id === offerId);

  const image =
    matchedImages[index] ?? matchedImages[0 + index] ?? matchedImages[0];
  return `/images/landing-page/offer-section/${image}`;
};

const OfferSectionCard: React.FC<{
  offer: HomePageOffer;
  handleClick: (offerId: string) => void;
  disabled: boolean;
  image: string;
}> = ({ offer, handleClick, disabled, image }) => {
  function renderDiscountValue() {
    if (
      offer.tradingHaltPrice &&
      offer.offerPrice &&
      !isEntitlementOrSpp(offer.type)
    ) {
      const { tradingHaltPrice, offerPrice } = offer;

      const discount =
        Math.abs(tradingHaltPrice - offerPrice) / tradingHaltPrice;

      return numeral(discount).format('0[.][00]%');
    }

    return 'N/A';
  }
  return (
    <div
      className={disabled ? undefined : 'cursor-pointer hidden md:block'}
      onClick={() => (disabled ? undefined : handleClick(offer.id))}
    >
      <div
        className={`w-screen ${
          offer.isPast ? 'bg-stone' : 'bg-moss'
        } md:w-[457px]`}
      >
        <Typography
          variant="body-bold-lg"
          className="py-1 text-center text-white"
        >
          {offer.isPast
            ? `Past ${getRaiseOfferType(offer.type)}`
            : `Announced Capital Raise`}
        </Typography>
      </div>
      <div
        className={`pt-[123px] md:pt-[269px] w-full h-[422px] md:h-[491px]`}
        style={{ backgroundImage: `url("${image}")`, backgroundSize: 'cover' }}
      >
        <div className="flex flex-col m-auto w-[300px] h-[362px] bg-white rounded-2xl shadow-lg md:w-[360px] md:h-[300px]">
          <div className="flex justify-between items-center h-[140px] bg-grey rounded-2xl md:h-[123px]">
            <div className="my-8 ml-8">
              <Typography variant="heading-sm" className="text-jet ">
                {offer.organisation?.ticker}
              </Typography>
              <Typography className="text-pine">
                {offer.organisation?.name}
              </Typography>
              {
                <Typography variant="body-xs" className="text-stone">
                  {offer.sector ?? 'Not Applicable'}
                </Typography>
              }
            </div>
            <div className="grid items-center mr-4 w-20 h-20 rounded-[50%]">
              <div className="relative w-full h-full rounded-[50%]">
                <Image
                  className="rounded-[50%]"
                  alt="Logo"
                  layout="fill"
                  objectFit="cover"
                  src={
                    offer.organisation?.logoThumbUrl ??
                    '/images/assets/logo-placeholder.svg'
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-1 justify-between items-center my-5 mx-8">
            <div className="grid grid-cols-2 gap-4 items-center w-full">
              <div>
                <Typography>Market cap</Typography>
                <Typography className="text-jet" variant="body-bold-sm">
                  {offer.marketCap
                    ? numeral(offer.marketCap).format('$0,0[.][00]a')
                    : '-'}
                </Typography>
              </div>
              <div>
                <Typography>Halt price</Typography>
                <Typography className="text-jet" variant="body-bold-sm">
                  {offer.tradingHaltPrice
                    ? numeral(offer.tradingHaltPrice).format('$0,0.000[0]')
                    : '-'}
                </Typography>
              </div>
              {offer.isPast ? (
                <>
                  <div>
                    <Typography>Discount</Typography>
                    <Typography className="text-jet" variant="body-bold-sm">
                      {renderDiscountValue()}
                    </Typography>
                  </div>
                  <div>
                    <Typography>Raise amount</Typography>
                    <Typography className="text-jet" variant="body-bold-sm">
                      {offer.outcome?.outcome
                        ? numeral(offer.outcome?.outcome).format('$0,0[.][00]a')
                        : '-'}
                    </Typography>
                  </div>
                </>
              ) : null}
            </div>
            {offer.isPast ? (
              <Typography className="text-center" variant="body-xs">
                Outcome announced{' '}
                {dayjs(offer.outcomeAnnouncedAt).format('dddd DD MMM, ha')}
              </Typography>
            ) : (
              <>
                <Typography className="text-center" variant="body-xs">
                  {offer?.organisation?.ticker ?? 'This company'} has entered a
                  trading halt with with the intention to conduct a capital
                  raise.
                </Typography>
                <Typography className="text-center" variant="body-xs">
                  Raise announced{' '}
                  {dayjs(offer.offerAnnouncedAt).format('dddd DD MMM, ha')}
                </Typography>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CardSkeleton = () => {
  return (
    <div>
      <div className="w-screen h-8 bg-ash md:w-[457px]">
        <div className="bg-white animate-pulse" />
      </div>
      <div className="pt-[273px] mb-2 w-full h-[491px] bg-silver animate-pulse">
        <div className="pb-4 m-auto w-[300px] bg-white rounded-2xl shadow-lg md:w-[360px]">
          <div className="flex justify-between items-center bg-grey rounded-2xl">
            <div className="my-8 ml-8">
              <div className="mb-2 w-20 h-4 bg-ash rounded-sm animate-pulse" />
              <div className="mb-2 w-24 h-4 bg-silver rounded-sm animate-pulse" />
              <div className="mb-2 w-16 h-4 bg-silver rounded-sm animate-pulse" />
            </div>
            <div className="grid items-center mr-4 mb-2 w-20 h-20 bg-silver rounded-[50%] animate-pulse" />
          </div>
          <div className="grid grid-cols-2 items-center my-5 mx-8 w-full">
            <div>
              <div className="mb-2 w-20 h-4 bg-silver rounded-sm animate-pulse" />
              <div className="mb-2 w-16 h-4 bg-ash rounded-sm animate-pulse" />
            </div>
            <div>
              <div className="mb-2 w-20 h-4 bg-silver rounded-sm animate-pulse" />
              <div className="mb-2 w-16 h-4 bg-ash rounded-sm animate-pulse" />
            </div>
          </div>
          <div className="m-auto mb-4 w-5/6 h-4 bg-silver animate-pulse" />
          <div className="m-auto mb-4 w-4/6 h-4 bg-silver animate-pulse" />
        </div>
      </div>
    </div>
  );
};

const OfferSection = () => {
  const { data, loading: liveLoading } =
    useQuery<HomePageOffersQueryData>(HomePageOffersQuery);
  const homePageOffers = data?.homePageOffers ?? [];

  const [selected, setSelected] = React.useState<string>('');
  const [selectedState, setSelectedState] = React.useState<HomePageOffer[]>([]);
  const [emailAddress, setEmailAddress] = React.useState('');
  const [hasClickedNavigateOffer, setHasClickedNavigateOffer] =
    React.useState(false);
  const router = useRouter();

  React.useEffect(() => {
    const offers = data?.homePageOffers ?? [];
    setSelected(offers[0]?.id ?? undefined);
    setSelectedState([...offers]);
  }, [data]);

  const handleSelectedState = (selected: string) => {
    if (!hasClickedNavigateOffer) {
      // window.analytics?.track(EVENTS.RTTV_V2_CLICKED_OFFER_NAVIGATION);
      setHasClickedNavigateOffer(true);
    }

    const initialIndex = homePageOffers.findIndex(
      (offer) => offer.id === selected,
    );

    setSelected(selected);
    const newEnd = homePageOffers.slice(0, initialIndex);
    const newStart = homePageOffers.slice(initialIndex, homePageOffers.length);
    setSelectedState([...newStart, ...newEnd]);
  };

  const getSelectedArrowValue = (
    offerId: string,
    direction: 'add' | 'subtract',
  ) => {
    if (direction === 'add') {
      if (offerId === homePageOffers[homePageOffers.length - 1].id)
        return homePageOffers[0].id;
      else
        return homePageOffers[
          homePageOffers.findIndex((offer) => offer.id === offerId) + 1
        ].id;
    } else {
      if (offerId === homePageOffers[0].id)
        return homePageOffers[homePageOffers.length - 1].id;
      else
        return homePageOffers[
          homePageOffers.findIndex((offer) => offer.id === offerId) - 1
        ].id;
    }
  };

  const handleGetStarted = () => {
    // window.analytics?.track(EVENTS.RTTV_V2_GET_STARTED);
    router.push(routes.authSignUpEmail.href(encodeURIComponent(emailAddress)));
  };

  return (
    <section className="flex overflow-x-hidden flex-col pt-[96px] pb-4 min-h-fit snap-start lg:pt-4">
      {/* <LogOnMount eventName={EVENTS.RTTV_V2_VIEW} /> */}
      <NavBar variant="home" />
      <div className="grid grid-cols-12 mx-auto mt-[96px] w-full min-h-[calc(100vh-96px)] lg:mt-[70px] lg:max-w-[1280px] lg:min-h-[calc(100vh-70px)]">
        <div className="grid col-span-12 place-items-center px-4 w-full md:max-w-[640px] lg:col-span-6 lg:pr-[152px]">
          <div>
            <Typography variant="display-xl" className="mb-10">
              A simple way to invest in listed capital raises
            </Typography>
            <hr className="mb-10 w-[80px] border-fresh lg:hidden" />
            <Typography variant="body-xl" className="mb-4 md:mb-12">
              Fresh Equities provides simple access to listed capital raises for
              institutions, family offices and high net worth individuals.{' '}
            </Typography>
            <div className="flex flex-col gap-4 w-full md:flex-row">
              <TextField
                variant="outlined"
                placeholder="Enter your email"
                className="flex-1"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
              <Button color="secondary" onClick={handleGetStarted}>
                Get started
              </Button>
            </div>
            <Typography
              variant="body-sm"
              className="mt-4 text-stone md:mb-14 lg:mb-0"
            >
              Create a free account and start investing in under 3 minutes.{' '}
            </Typography>
            <hr className="my-10 w-[80px] border-fresh md:hidden" />
          </div>
        </div>
        <div className="grid col-span-12 place-items-start lg:col-span-6 lg:place-items-center lg:pr-0">
          <div>
            <Typography variant="display-lg" className="px-4 mb-5 lg:hidden">
              Recent Offers
            </Typography>

            <div className="md:flex md:gap-8 md:px-4 lg:px-0">
              {liveLoading
                ? [1, 2, 3, 4, 5].map((v) => <CardSkeleton key={v} />)
                : selectedState.map((offer, i) => (
                    <OfferSectionCard
                      key={offer.id}
                      offer={offer}
                      disabled={i === 0}
                      handleClick={handleSelectedState}
                      image={getImage(
                        offer.sector,
                        offer.id,
                        data?.homePageOffers?.filter(
                          (o) => o.type === offer.type,
                        ) ?? [],
                      )}
                    />
                  ))}
            </div>
            <div className="flex justify-center md:w-screen lg:w-[456px]">
              <div className="flex gap-3 justify-center items-center pt-28 w-full max-w-[100vw] md:mb-28 lg:pr-0 lg:mb-0">
                <button
                  onClick={() =>
                    handleSelectedState(
                      getSelectedArrowValue(selected, 'subtract'),
                    )
                  }
                  className="grid place-items-center w-[44px] h-[44px] text-jet bg-grey rounded-[50%]"
                >
                  <ChevronLeftIcon />
                </button>

                {(liveLoading
                  ? [
                      { id: '1' },
                      { id: '2' },
                      { id: '3' },
                      { id: '4' },
                      { id: '5' },
                    ]
                  : homePageOffers
                ).map((v) => (
                  <button
                    onClick={() => handleSelectedState(v.id)}
                    key={v.id}
                    className={`hidden md:grid place-items-center w-[10px] h-[10px] text-jet ${
                      selected === v.id
                        ? 'bg-fresh shadow-00 box-content border-white border-4'
                        : 'bg-silver border-4 box-content border-white'
                    } rounded-[50%]`}
                  />
                ))}
                <button
                  onClick={() =>
                    handleSelectedState(getSelectedArrowValue(selected, 'add'))
                  }
                  className="grid place-items-center w-[44px] h-[44px] text-jet bg-grey rounded-[50%]"
                >
                  <ChevronRightIcon />
                </button>
              </div>
            </div>
            <div className="flex justify-center my-9 w-screen md:hidden">
              <Link passHref href={routes.authSignUpEmail.href(emailAddress)}>
                <Button
                  className="md:hidden"
                  color="secondary"
                  // onClick={() =>
                  //   window.analytics?.track(EVENTS.RTTV_V2_GET_STARTED)
                  // }
                >
                  Create a free account
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OfferSection;

import numeral from 'numeral';
// Utils
import { calculateShares, calculateBidFromShares } from './bid-helpers';
import { ListedOptions, OptionsClass, RaiseType } from './interfaces';

export function getRaiseOfferType(type: RaiseType | null) {
  switch (type) {
    case 'entitlement':
      return 'Entitlement Offer';
    case 'placement':
      return 'Placement';
    case 'spp':
      return 'Share Purchase Plan';
    default:
      return 'Capital Raise';
  }
}

export const getBookType = (type: string) => {
  switch (type) {
    case 'daybook':
      return 'Daybook';
    case 'wall_cross':
      return 'Wall-cross';
    default:
      return 'Daybook';
  }
};

export const getBidType = (type: RaiseType) => {
  switch (type) {
    case 'placement':
      return 'Bid for allocation';
    case 'spp':
      return 'Bid for top-up allocation';
    case 'entitlement':
      return 'Bid for shortfall';
    default:
      return 'Bid for allocation';
  }
};

export const getOptionsClassLabel = (optionClass: OptionsClass) => {
  switch (optionClass) {
    case 'unlisted':
      return 'Unlisted';
    case 'listed':
      return 'Listed';
    case 'may be listed':
      return 'May be listed';
    case 'to be listed':
      return 'To be listed';
    default:
      return '';
  }
};

export const getBidValue = (
  bid: string,
  offerPrice: number,
  isAud: boolean,
) => {
  const hasBid = bid !== '' && bid !== undefined;
  if (isAud && hasBid) {
    return `${numeral(calculateShares(Number(bid), offerPrice)).format('0,0')}`;
  }
  if (!isAud && hasBid) {
    return `${numeral(calculateBidFromShares(Number(bid), offerPrice)).format(
      '$0,0',
    )}`;
  }
  return '-';
};

export const calculateOptionsValue = (
  numerator: number,
  denominator: number,
  shares: number,
) => Math.floor((numerator / denominator) * shares);

export const getOptionValue = (
  bid: string,
  { numerator, denominator }: ListedOptions,
  offerPrice: number,
  isAud: boolean,
) => {
  const hasBid = bid !== '' && bid !== undefined;
  if (isAud && hasBid && numerator && denominator) {
    const shares = calculateShares(Number(bid), offerPrice);
    return `${numeral(
      calculateOptionsValue(numerator, denominator, shares),
    ).format('0,0')}`;
  }
  if (!isAud && hasBid && numerator && denominator) {
    return `${numeral(
      calculateOptionsValue(numerator, denominator, Number(bid)),
    ).format('0,0')}`;
  }
  return '-';
};

/**
 * Check if the type is `entitlement` or `spp`.
 *
 * @param type
 * @returns True if the type is `entitlement` or `spp`.
 */
export function isEntitlementOrSpp(type: RaiseType | null | undefined) {
  return type === 'entitlement' || type === 'spp';
}

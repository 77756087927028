export function getPriceSafely(price: number) {
  // Add the insignificant value to tackle precision issue.
  // We know that so far max decimal points for an offer price is 5 digit.
  // Adding 0.00000001 should not affect end result.
  // This is a temporary workaround.
  const bidAmount = price + 0.00000001;

  return Number(bidAmount.toFixed(7));
}

export function calculateBidFromShares(shares: number, offerPrice: number) {
  return getPriceSafely(shares * offerPrice);
}

export function calculateShares(bid: number, offerPrice: number) {
  return Math.floor(getPriceSafely(bid / offerPrice));
}

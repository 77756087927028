import { gql } from '@apollo/client';
import { RaiseType } from '../../utils-ts/interfaces';

export interface HomePageOffersQueryData {
  homePageOffers: HomePageOffer[] | null;
}

export interface HomePageOffer {
  id: string;
  marketCap: number | null;
  organisation: Organisation | null;
  outcomeAnnouncedAt: string;
  outcome: {
    outcome: number | null;
  } | null;
  offerPrice: number | null;
  offerAnnouncedAt: string;
  sector: string;
  tradingHaltPrice: number | null;
  isPast: boolean;
  type: RaiseType;
}

export interface Organisation {
  id: string;
  bannerUrl: string | null;
  logoThumbUrl: string | null;
  name: string;
  ticker: string | null;
}

export const HomePageOffersQuery = gql`
  query HomePageOffersQuery {
    homePageOffers {
      id

      marketCap
      organisation {
        id

        bannerUrl
        logoThumbUrl
        name
        ticker
      }

      outcomeAnnouncedAt
      offerAnnouncedAt
      offerPrice
      outcome {
        outcome
      }
      sector
      tradingHaltPrice
      isPast
      type
    }
  }
`;

export default HomePageOffersQuery;

import React, { useEffect, useRef, createRef, useState } from 'react';
// Modules
import { GetServerSideProps, NextPage } from 'next/types';
import SmartBanner from 'react-smartbanner';
// MUI Core
import { makeStyles } from '@material-ui/core/styles';
// Components
import AsFeaturedInSection from '../components-ts/home/AsFeaturedInSection';
import CarouselSection from '../components-ts/home/CarouselSection';
import CallToActionSection from '../components-ts/home/CallToActionSection';
import DefinitionSection from '../components-ts/home/DefinitionSection';
import ImageSection from '../components-ts/home/ImageSection';
import MobileAppSection from '../components-ts/home/MobileAppSection';
import OfferSection from '../components-ts/home/OfferSection';
import LandingSEO from '../components-ts/home/seo/LandingSEO';
import Root from '../components-ts/layout/Root';
import Footer from '../components-ts/layout/Footer';
// Utils
// import { EVENTS } from '../utils-ts/analytics';
import { amILogin } from '../utils-ts/authorize';
import { homePageContent } from '../utils-ts/homepage-content';
import { initApollo } from '../utils-ts/client';
import { href } from '../utils-ts/pages';

// CSS for SmartBanner
import 'react-smartbanner/dist/main.css';
import { useFlagQuery } from '../graphql-ts/queries/FlagQuery.graphql';
import { Flag } from '../utils-ts/types';

const useStyles = makeStyles((theme) => ({
  scrollSnapContainer: {
    overflow: 'auto',
    height: '100%',
  },
}));

const IndexPage: NextPage = () => {
  const classes = useStyles();

  const sectionRefs = useRef(
    Array.from({ length: 5 }, () => createRef<HTMLElement>()),
  );

  const [definitionsViewed, setDefinitionsViewed] = useState(false);
  const [imageLeftViewed, setImageLeftViewed] = useState(false);
  const [imageRightViewed, setImageRightViewed] = useState(false);
  const [appViewed, setAppViewed] = useState(false);

  function setAnimations() {
    const observerOptions = {
      rootMargin: '0px',
      threshold: 0.4,
    };

    const animateIn: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          switch (entry.target.id) {
            case 'hs-definitions':
              return !definitionsViewed ? setDefinitionsViewed(true) : null;
            case 'hs-image-left':
              return !imageLeftViewed ? setImageLeftViewed(true) : null;
            case 'hs-image-right':
              return !imageRightViewed ? setImageRightViewed(true) : null;
            case 'hs-app':
              return !appViewed ? setAppViewed(true) : null;
            default:
              return null;
          }
        }
        return null;
      });
    };

    const observer = new IntersectionObserver(animateIn, observerOptions);

    for (const sectionRef of sectionRefs.current) {
      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }
    }
  }

  function setAllViewed() {
    setDefinitionsViewed(true);
    setImageLeftViewed(true);
    setImageRightViewed(true);
    setAppViewed(true);
  }

  useEffect(() => {
    // Older browsers missing IntersectionObserver, don't want to polyfill
    if (
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'isIntersecting' in window.IntersectionObserverEntry.prototype
    ) {
      setAnimations();
    } else {
      setAllViewed();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Give the correct css for our smart banner
    // https://github.com/patw0929/react-smartbanner/blob/master/src/components/SmartBanner.js
    window.document
      .querySelector('html')
      ?.classList.add('smartbanner-margin-top');
  }, []);

  const { data, loading: flagLoading } = useFlagQuery(Flag.rttv_v2);
  const isHomePageOfferEnabled = !!data?.flag;

  return (
    <>
      <SmartBanner
        // onClose={() => window?.analytics?.track(EVENTS.SMART_BANNER_CLOSED)}
        // onInstall={() => window?.analytics?.track(EVENTS.SMART_BANNER_CLICKED)}
        title="Fresh Equities"
      />

      <Root variant="home">
        <LandingSEO />
        <div className={classes.scrollSnapContainer} id="scroll-container">
          {!flagLoading ? (
            isHomePageOfferEnabled ? (
              <OfferSection />
            ) : (
              <CarouselSection pageContent={homePageContent} />
            )
          ) : (
            <section className="flex overflow-x-hidden flex-col pt-[96px] pb-4 h-screen snap-start lg:pt-4" />
          )}
          <DefinitionSection
            id="hs-definitions"
            pageContent={homePageContent}
            viewed={definitionsViewed}
            ref={sectionRefs.current[0]}
          />
          <ImageSection
            id="hs-image-left"
            pageContent={homePageContent}
            previousViewed={definitionsViewed}
            ref={sectionRefs.current[1]}
            section={3}
            viewed={imageLeftViewed}
          />
          <MobileAppSection
            id="hs-app"
            pageContent={homePageContent}
            previousViewed={imageLeftViewed}
            ref={sectionRefs.current[2]}
            viewed={appViewed}
          />
          <ImageSection
            id="hs-image-right"
            pageContent={homePageContent}
            previousViewed={appViewed}
            ref={sectionRefs.current[3]}
            section={5}
            viewed={imageRightViewed}
          />
          <CallToActionSection pageContent={homePageContent} />
          <AsFeaturedInSection pageContent={homePageContent} />
          <Footer />
        </div>
      </Root>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  // Check for cookie first
  if (ctx.req.headers.cookie) {
    const apolloClient = initApollo({ cookie: ctx.req.headers.cookie });
    // Redirect to offers page if logged in user
    const me = await amILogin(apolloClient);
    if (me) {
      return {
        props: {},
        redirect: {
          destination: href.liveCapitalRaises,
        },
      };
    }
  }

  // Otherwise render the homepage
  return {
    props: {},
  };
};

export default IndexPage;
